import VirtualTerminal from '@engine/features/virtual_terminal';
import { exists } from '@engine/lib/utility/exists';
import { toggleDisabledAttribute } from '@engine/lib/utility/toggle_disabled_attribute';

function disableConsumerEmailSelect() {
  var $consumerSelectWrapper = $('.consumer-email-select');

  $consumerSelectWrapper.hide();
  $consumerSelectWrapper.find('select, input').prop('disabled', true);
}

function handleSendByNone() {
  VirtualTerminal.RequiredFields.makeDivHidden('email-fieldset');
  VirtualTerminal.RequiredFields.makeDivHidden('phone-fieldset');

  disableConsumerEmailSelect();
  toggleDisabledAttribute($('.consumer-email-input').find('input'), true);
  toggleDisabledAttribute($('#phone-fieldset').find('input'), true);
}

function handleSendByEmail() {
  VirtualTerminal.RequiredFields.makeDivVisible('email-fieldset');
  VirtualTerminal.RequiredFields.makeDivHidden('phone-fieldset');

  if($('#email-field-input').prop('checked')) {
    toggleDisabledAttribute($('.consumer-email-input').find('input'), false);
  } else {
    toggleDisabledAttribute($('.consumer-email-select').find('select, input'), false);
  }

  toggleDisabledAttribute($('#phone-fieldset').find('input'), true);
}

function handleSendBySms() {
  VirtualTerminal.RequiredFields.makeDivVisible('phone-fieldset');
  VirtualTerminal.RequiredFields.makeDivHidden('email-fieldset');

  toggleDisabledAttribute($('#phone-fieldset').find('input'), false);
  disableConsumerEmailSelect();
  toggleDisabledAttribute($('.consumer-email-input').find('input'), true);
}

const sendByOptions = {
  none: handleSendByNone,
  email: handleSendByEmail,
  sms: handleSendBySms
};

function listenForSendByOptionChange() {
  if(!exists($('#sales_operator_pay_by_link_terminal_id'))) { return; }

  const AVAILABLE_OPTIONS = ['send_link_by_none', 'send_link_by_email', 'send_link_by_sms'];

  AVAILABLE_OPTIONS.forEach(function(option) {
    if(exists($('#' + option))) {
      $('#' + option).on('change', function() {
        const optionValue = $('input[type="radio"]:checked', '.switch-field').val();

        sendByOptions[optionValue]();
      });
    }
  });
}

const SalesOperatorPayByLinkConsumers = {
  listenForSendByOptionChange
};

export default SalesOperatorPayByLinkConsumers;
