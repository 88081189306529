import PayByLinkConsumers from '@engine/features/virtual_terminal/vt_update_pay_by_link_consumers';

function terminalUpdateRequest(action, terminalId) {
  $.ajax({
    type: 'GET',
    url: '/pay_by_link/' + action + '/' + terminalId,
    dataType: 'json',
    contentType: 'application/json',
    data: {
      terminal_id: terminalId
    }
  }).done(function(data) {
    PayByLinkConsumers.updateFields(data.has_consumers, terminalId);

    // Enable email input field on switching channels when no consumers
    if (!data.has_consumers) {
      $('#email-field-input').prop('checked', true);
    }

    // The above code removes the disabled attr for email select no matter what channel
    // for `Send Payment Link By` is selected. We need to disable the `select`
    // when a different channel is selected (in case of an `InputDataMissingError`).
    if(!$('#send_link_by_email').is(':checked')) {
      var $consumerSelectWrapper = $('.consumer-email-select');

      $consumerSelectWrapper.hide();
      $consumerSelectWrapper.find('select, input').prop('disabled', true);
    }
  });

}

function terminalUpdate() {
  if ($('#sales_operator_pay_by_link_terminal_id').val()) {
    var terminalId = $('.sales_operator_virtual_terminal_terminal_id').val();

    terminalUpdateRequest('update', terminalId);
  }
}

const PayByLink = {
  update: terminalUpdate
};

export default PayByLink;
