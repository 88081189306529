import PayByLink from '@app/features/sales_operator/vt_update_pay_by_link';
import SalesOperatorPayByLinkConsumers
  from '@app/features/sales_operator/vt_update_pay_by_link_consumers';
import virtualTerminalUpdate from '@app/features/sales_operator/vt_terminal_change';

const VirtualTerminal = {
  update: virtualTerminalUpdate,
  PayByLinkConsumers: SalesOperatorPayByLinkConsumers,
  PayByLink
};

const SalesOperator = {
  VirtualTerminal
};

export default SalesOperator;
