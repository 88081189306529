import VirtualTerminal from '@engine/features/virtual_terminal';

function virtualTerminalRequest(action, terminalId, trxType) {
  $.ajax({
    type: 'GET',
    url: '/virtual-terminal/' + action,
    dataType: 'json',
    contentType: 'application/json',
    data: {
      terminal_id: terminalId,
      transaction_type: trxType
    }
  }).done(function(data) {
    // update cvv field and decimal_amount exponent
    VirtualTerminal.RequiredFields.updateRequiredFields(data);

    // update pbl email field
    if ($('#send_link_by_email').is(':checked')) {
      VirtualTerminal.RequiredFields.makeFieldRequired('pay_by_link_transaction_customer_email');
    }
  });
}

function virtualTerminalUpdate() {
  if ($('.sales_operator_virtual_terminal_terminal_id').val()) {
    var terminalId = $('.sales_operator_virtual_terminal_terminal_id').val();
    var trxType = $('.sales_operator_virtual_terminal_terminal_id')
      .closest('form').attr('action').split('/').pop();

    virtualTerminalRequest('update_data_on_terminal_selection', terminalId, trxType);
  }
}

export default virtualTerminalUpdate;
